import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import FormSection from "controls/FormSection";
import Message from "controls/Message";
import { formatInTimeZone } from "date-fns-tz";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useTimeZone from "hooks/useTimeZone";
import TimeInput from "inputs/TimeInput";
import { Camera } from "mdi-material-ui";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";
import ReadminObjectsTable, { ReadminObjectsTableFragment } from "readmin_pages/shared/ReadminObjectsTable";

const ISO_DATE_FORMAT = "yyyy-MM-dd";

CutNewFootageDialog.buttonProps = {
  icon: <Camera />,
  content: "Cut new footage",
};

export default function CutNewFootageDialog({ gameId, onSubmit = noop, ...others }) {
  const [court, courtSet] = useState(null);
  const [surface, surfaceSet] = useState(null);
  const [playedAt, playedAtSet] = useState(null);
  const [data] = useData(
    gql`
      query CutNewFootageDialog($gameId: ID!) {
        game(id: $gameId) {
          id
          playedAt
          completedAt
          durationSeconds
          isRecorded
          isFullRecordingAvailable
          isValidDuration
          durationError
          club {
            id
          }
          court {
            id
            name
          }
          cameraOutages {
            id
            startedAt
            finishedAt
            active
          }
          serverOutages {
            id
            startedAt
            finishedAt
            active
          }
        }
      }
    `,
    {
      gameId,
    },
  );

  let timezone = useTimeZone();
  let date = data?.game.playedAt;
  date = date && formatInTimeZone(date, timezone, ISO_DATE_FORMAT);

  useLayoutEffect(() => {
    if (data) {
      courtSet(data?.game.court);
      playedAtSet(data?.game.playedAt);
    }
  }, [data]);

  const [dataRecordings] = useData(
    gql`
      query CutNewFootageDialog($courtId: ID!, $range: DateRange) {
        court(id: $courtId) {
          id
          surface {
            id
          }
          recordings(range: $range) {
            id
            ...ReadminObjectsTableFragment
          }
        }
      }
      ${ReadminObjectsTableFragment}
    `,
    {
      courtId: court?.id,
      range: date && {
        from: date,
        to: date,
      },
    },
    {
      skip: !court || !date,
    },
  );
  const courtSurfaceId = dataRecordings?.court?.surface?.id;
  const gameCutNewFootage = useActionFragment("gameCutNewFootage", "game { id video { id } }");

  useEffect(() => {
    surfaceSet(courtSurfaceId ? { id: courtSurfaceId, __typename: "Surface" } : null);
  }, [courtSurfaceId]);

  return (
    <FormDialog
      header="Cut new video footage"
      loading={!data || !dataRecordings}
      onSubmit={async (formData) => {
        await gameCutNewFootage({
          input: {
            gameId,
            ...formData,
          },
        });
        await onSubmit();
      }}
      submitContent="Cut new video footage"
      {...others}
    >
      {data?.game.isRecorded && (
        <Message type="success" title="Recording found" content="There is a recording available for this game" />
      )}

      <FormSection header="Issues" defaultExpanded={true}>
        <Issues game={data?.game} />
      </FormSection>

      <Message
        type="warning"
        content={
          "This game’s details will be changed and a new video will be processed. Any existing video will be replaced."
        }
      />
      <Field
        name="playedAt"
        value={playedAt}
        onChange={playedAtSet}
        required
        label="Start time"
        input={<TimeInput date={date} />}
      />
      <Field
        name="completedAt"
        defaultValue={data?.game.completedAt}
        required
        label="End time"
        input={<TimeInput date={date} />}
      />
      <Field
        name="court"
        label="Court"
        value={court}
        onChange={courtSet}
        required
        input={<ReadminObjectInput optionsTypename="Court" />}
      />
      <Field
        name="surface"
        label="Surface"
        value={surface}
        onChange={surfaceSet}
        required
        disabled={!courtSurfaceId}
        input={<ReadminObjectInput optionsTypename="Surface" />}
      />
      <ReadminObjectsTable header={"Recordings made on the same date."} rows={dataRecordings?.court?.recordings} />
    </FormDialog>
  );
}

function Issues({ game }) {
  const issues = [];
  if (!game?.isRecorded) {
    issues.push(["No recording is available", `Unfortunately there was no recording found for this game`, "error"]);
  }
  if (game?.isRecorded && !game?.isFullRecordingAvailable) {
    issues.push(["Partial recording", "Unfortunately only part of this game was recorded", "error"]);
  }
  if (!game?.isValidDuration) {
    issues.push([
      "Suspicious game details",
      `This game had some unexpected details - ${game?.durationError}`,
      "warning",
    ]);
  }
  if (game?.cameraOutages?.length > 0) {
    issues.push(["Camera Outage", createOutageMessage(game.cameraOutages, "camera"), "error"]);
  }
  if (game?.serverOutages?.length > 0) {
    issues.push(["Server Outage", createOutageMessage(game.serverOutages, "venue server"), "error"]);
  }
  return (
    <>
      {issues?.map(([title, content, type], i) => (
        <Message key={i} title={title} content={content} type={type} />
      ))}
      {!issues.length && <Message content="No issues found" />}
    </>
  );
}

function createOutageMessage(outages, type) {
  let message = "";
  const datetimeFormat = "HH:mm dd-MM-yyyy";
  if (outages.length > 1) {
    message = `There were multiple outages. See ${type} for more info`;
  } else {
    const outage = outages[0];
    message = outage.active
      ? `Currently offline since ${formatInTimeZone(
          outage.startedAt,
          useTimeZone(),
          datetimeFormat,
        )} (${useTimeZone()})`
      : `Offline from ${formatInTimeZone(outage.startedAt, useTimeZone(), datetimeFormat)} to ${formatInTimeZone(
          outage.finishedAt,
          useTimeZone(),
          datetimeFormat,
        )} (${useTimeZone()})`;
  }
  return message;
}
