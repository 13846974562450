import { gql } from "@apollo/client";
import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import FormFieldContainer from "controls/FormFieldContainer";
import useData from "hooks/useData";
import useFormDialogs from "hooks/useFormDialogs";
import { Delete, Information, Plus } from "mdi-material-ui";
import React, { useId } from "react";

import SelectReadminObjectDialog from "./SelectReadminObjectDialog";
import ReadminObjectViewDetailsDialog from "./ViewDetailsDialog";

ReadminObjectsInput.toFormData = (value) => {
  return value?.map((readminObject) => ({
    id: readminObject.id,
    typename: readminObject.__typename,
  }));
};

ReadminObjectsInput.defaultValue = [];

export default function ReadminObjectsInput({
  formField,
  value,
  onChange,
  disabled,
  optionsTypename,
  optionsFilters = {},
  ...others
}) {
  const id = useId();
  const [formDialogs, formDialogsOpen] = useFormDialogs();

  return (
    <FormFieldContainer data-readmin-objects-input id={id} disabled={disabled} {...others}>
      {formDialogs}
      <List disablePadding>
        {!value.length && disabled && (
          <ListItem>
            <ListItemText primary="(empty)" />
          </ListItem>
        )}
        {value.map((readminObject, readminObjectIndex) => (
          <ListItem
            key={readminObjectIndex}
            secondaryAction={
              <>
                <IconButton
                  onClick={() =>
                    formDialogsOpen(
                      <ReadminObjectViewDetailsDialog id={readminObject.id} typename={readminObject.__typename} />,
                    )
                  }
                >
                  <Information />
                </IconButton>
                {!disabled && (
                  <IconButton
                    edge="end"
                    onClick={() =>
                      onChange([
                        //
                        ...value.slice(0, readminObjectIndex),
                        ...value.slice(readminObjectIndex + 1),
                      ])
                    }
                  >
                    <Delete />
                  </IconButton>
                )}
              </>
            }
          >
            <ObjectListItemText readminObject={readminObject} />
          </ListItem>
        ))}
        {!disabled && (
          <ListItem disablePadding>
            <ListItemButton
              data-readmin-objects-input-add
              onClick={() =>
                formDialogsOpen(
                  <SelectReadminObjectDialog
                    selectedReadminObjects={value}
                    optionsTypename={optionsTypename}
                    optionsFilters={optionsFilters}
                    onSubmit={(valueNew) => onChange([...value, valueNew])}
                    {...(others.label && { header: `${others.label}...` })}
                  />,
                )
              }
            >
              <ListItemIcon>
                <Plus />
              </ListItemIcon>
              <ListItemText primary="Add" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </FormFieldContainer>
  );
}

function ObjectListItemText({ readminObject }) {
  const [data] = useData(
    gql`
      query ReadminObjectsInput($id: ID, $typename: String) {
        readminObject(id: $id, typename: $typename) {
          id
          readminName
        }
      }
    `,
    {
      id: readminObject.id,
      typename: readminObject.__typename,
    },
  );

  return <ListItemText primary={data?.readminObject.readminName} />;
}
