import { gql } from "@apollo/client";
import FormText from "controls/FormText";
import MultiValuesField from "controls/MultiValuesField";
import useData from "hooks/useData";
import DateInput from "inputs/DateInput";
import PasswordInput from "inputs/PasswordInput";
import SuggestiveTextInput from "inputs/SuggestiveTextInput";
import TextInput, { IpAdddressInput, MacAddressInput } from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const NetworkDevicesFormFragment = gql`
  fragment NetworkDevicesFormFragment on NetworkDevice {
    venueServer {
      id
    }
    model
    details
    purchaseDate
    ipAddress
    macAddress
    deviceUsername
    devicePassword
  }
`;

export default function NetworkDevicesForm({ networkDevices }) {
  const [data] = useData(gql`
    query NetworkDevicesForm {
      networkDeviceModels
    }
  `);

  return (
    <>
      <FormText uiStringKey="readmin.network_device.description" />
      <MultiValuesField
        name="venueServer"
        label="Venue Server"
        defaultValues={networkDevices?.map((nd) => nd.venueServer)}
        input={<ReadminObjectInput optionsTypename="VenueServer" />}
        helperTextUiStringKey="readmin.network_device.venue_server"
      />
      <MultiValuesField
        name="model"
        label="Model"
        required
        defaultValues={networkDevices?.map((nd) => nd.model)}
        input={<SuggestiveTextInput suggestions={data?.networkDeviceModels} />}
        helperTextUiStringKey="readmin.network_device.model"
      />
      <MultiValuesField
        name="purchaseDate"
        label="Purchase Date"
        defaultValues={networkDevices?.map((nd) => nd.purchaseDate)}
        input={<DateInput />}
        helperTextUiStringKey="readmin.network_device.purchase_date"
      />
      <MultiValuesField
        name="details"
        label="Details"
        defaultValues={networkDevices?.map((nd) => nd.details)}
        input={<TextInput multiline minRows={5} />}
        helperTextUiStringKey="readmin.network_device.details"
      />
      <MultiValuesField
        name="ipAddress"
        label="IP Address"
        defaultValues={networkDevices?.map((nd) => nd.ipAddress)}
        input={<IpAdddressInput />}
        helperTextUiStringKey="readmin.network_device.ip_address"
      />
      <MultiValuesField
        name="macAddress"
        label="MAC Address"
        defaultValues={networkDevices?.map((nd) => nd.macAddress)}
        input={<MacAddressInput />}
        helperTextUiStringKey="readmin.network_device.mac_address"
      />
      <MultiValuesField
        name="deviceUsername"
        label="Device Username"
        defaultValues={networkDevices?.map((nd) => nd.deviceUsername)}
        input={<TextInput />}
        helperTextUiStringKey="readmin.network_device.device_username"
      />
      <MultiValuesField
        name="devicePassword"
        label="Device Password"
        defaultValues={networkDevices?.map((nd) => nd.devicePassword)}
        input={<PasswordInput />}
        helperTextUiStringKey="readmin.network_device.device_password"
      />
    </>
  );
}
