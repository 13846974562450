import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import useIsAdmin from "hooks/useIsAdmin";
import SwitchInput from "inputs/SwitchInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const VideosFormFragment = gql`
  fragment VideosFormFragment on Video {
    id
    preventDeletion
    disabled
    surface {
      id
    }
    court {
      id
    }
    camera {
      id
    }
  }
`;

export default function VideosForm({ videos }) {
  videos = videos?.map((video) => ({
    ...video,
  }));
  const isAdmin = useIsAdmin();

  return (
    <>
      <MultiValuesField
        label="Camera"
        defaultValues={videos?.map((video) => video.camera)}
        disabled
        input={<ReadminObjectInput optionsTypename="Camera" />}
      />
      <MultiValuesField
        label="Surface"
        name="surface"
        required
        defaultValues={videos?.map((video) => video.surface)}
        input={<ReadminObjectInput optionsTypename="Surface" />}
      />
      <MultiValuesField
        label="Court"
        name="court"
        defaultValues={videos?.map((video) => video.court)}
        required
        input={<ReadminObjectInput optionsTypename="Court" />}
        helperText={
          <>
            Changing the court here only affects advertising assets, since the video is already cut / to be cut from
            selected camera. If you want to change the footage from another camera / court. Please{" "}
            <b>Cut New Footage</b> for the <b>Game</b>.
          </>
        }
      />
      <MultiValuesField
        label="Disabled"
        name="disabled"
        defaultValues={videos?.map((video) => video.disabled)}
        input={<SwitchInput />}
      />
      {isAdmin && (
        <MultiValuesField
          label="Prevent Deletion"
          name="preventDeletion"
          defaultValues={videos?.map((video) => video.preventDeletion)}
          input={<SwitchInput />}
        />
      )}
    </>
  );
}
