import { AppBar, Button, ButtonBase, List, ListItemIcon, ListItemText, Menu, MenuItem, Paper } from "@mui/material";
import Scrollable from "controls/Scrollable";
import { COLOR_SECONDARY } from "helpers/muiTheme";
import useEnforceAuth from "hooks/useEnforceAuth";
import { ChevronDoubleLeft, ChevronDoubleRight, MenuDown } from "mdi-material-ui";
import PublicForm from "public_pages/PublicForm";
import React, { createContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useWindowSize } from "react-use-size";
import ReactPageFallthrough from "ReactPageFallthrough";
import ReadminMenuContent from "readmin_pages/ReadminMenuContent";
import ReadminSidebarContent from "readmin_pages/ReadminSidebarContent";
import READMIN_ROUTES from "READMIN_ROUTES";

export const readminContext = createContext();

const SIDEBAR_COLLAPSED_WIDTH = 25;
const SIDEBAR_EXPANDED_WIDTH = 250;

export default function Readmin() {
  const enforceAuth = useEnforceAuth({ enforceAuthenticated: true });

  const windowSize = useWindowSize();
  const sidebarPersistent = windowSize.width > SIDEBAR_EXPANDED_WIDTH * 2;
  const [sidebarOpen, sidebarOpenSet] = useState(sidebarPersistent);

  if (enforceAuth.loading) {
    return <PublicForm dense loading />;
  }

  return (
    <readminContext.Provider value={{ sidebarOpen }}>
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          height: "100svh",
          width: "100svw",
          overflow: "hidden",
          alignItems: "stretch",
        }}
      >
        <AppBar style={{ display: "flex", flexFlow: "row wrap", position: "relative", zIndex: 11 }}>
          <ReadminMenuContent />
        </AppBar>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "stretch",
          }}
        >
          <Paper
            square
            elevation={5}
            style={{
              flex: `0 0 ${SIDEBAR_COLLAPSED_WIDTH}px`,
              position: "relative",
              zIndex: 10,
              ...(sidebarOpen && {
                flex: `0 0 ${SIDEBAR_EXPANDED_WIDTH}px`,
                ...(!sidebarPersistent && {
                  marginRight: -(SIDEBAR_EXPANDED_WIDTH - SIDEBAR_COLLAPSED_WIDTH),
                }),
              }),
              display: "flex",
              flexFlow: "column nowrap",
              justifyContent: "stretch",
              transition: "flex-basis ease 0.2s, margin ease 0.2s",
            }}
          >
            <Scrollable
              style={{
                flex: "1 1 auto",
              }}
            >
              <div style={{ width: SIDEBAR_EXPANDED_WIDTH, opacity: sidebarOpen ? 1 : 0 }}>
                <List dense disablePadding>
                  <ReadminSidebarContent />
                </List>
              </div>
            </Scrollable>
            <Paper
              square
              style={{
                position: "absolute",
                top: 20,
                right: -20,
                width: 40,
                height: 40,
                borderRadius: "50%",
                fontSize: 16,
                ...(sidebarPersistent && { color: COLOR_SECONDARY }),
              }}
              component={ButtonBase}
              onClick={() => sidebarOpenSet((o) => !o)}
            >
              {sidebarOpen ? <ChevronDoubleLeft fontSize="inherit" /> : <ChevronDoubleRight fontSize="inherit" />}
            </Paper>
          </Paper>
          <Scrollable
            style={{
              flex: "1 1 auto",
            }}
          >
            <Routes>
              {Object.entries(READMIN_ROUTES).map(([path, Component]) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
              <Route path="*" element={<ReactPageFallthrough />} />
            </Routes>
          </Scrollable>
        </div>
      </div>
    </readminContext.Provider>
  );
}

export function ReadminMenuImageItem({ src, ...others }) {
  return (
    <Button color="inherit" {...others}>
      <img src={src} alt="" style={{ height: "2.5em", width: "auto" }} />
    </Button>
  );
}

export function ReadminMenuItem({ icon, label, ...others }) {
  return (
    <Button color="inherit" {...others}>
      {icon}
      {label}
    </Button>
  );
}

export function ReadminMenuDropdown({ icon, label, alignOnRight = false, children, ...others }) {
  const [anchorEl, anchorElSet] = useState(null);
  const windowSize = useWindowSize();

  return (
    <>
      <Button color="inherit" {...others} onClick={(event) => anchorElSet(event.currentTarget)}>
        {icon}
        {windowSize.width > 640 && <>{label}</>}
        <MenuDown />
      </Button>
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: alignOnRight ? "right" : "left" }}
        transformOrigin={{ vertical: "top", horizontal: alignOnRight ? "right" : "left" }}
        open={!!anchorEl}
        onClose={() => anchorElSet(null)}
        anchorEl={anchorEl}
        component="div"
        keepMounted
      >
        {children}
      </Menu>
    </>
  );
}

export function ReadminMenuDropdownItem({ icon, label, ...others }) {
  return (
    <MenuItem component="a" {...others}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={label} />
    </MenuItem>
  );
}
