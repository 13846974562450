import { gql } from "@apollo/client";
import { readminPageContext } from "helpers/contexts";
import { Information } from "mdi-material-ui";
import React, { useContext } from "react";

export default function ReadminLink({
  to,
  dense = false, // only display logo if available
}) {
  const Component = to?.readminUrl ? "a" : "span";

  const { openViewDetailsDialog } = useContext(readminPageContext);

  return (
    to && (
      <span>
        <Component href={to.readminUrl}>
          {to.readminLogoUrl && (
            <img
              src={to.readminLogoUrl}
              style={{ height: "2em", margin: "0 .5em .3em", verticalAlign: "middle" }}
              title={to.readminName}
            />
          )}
          {dense && !to.readminLogoUrl && (
            <span
              style={{
                display: "inline-block",
                whiteSpace: "nowrap",
                maxWidth: "20em",
                overflow: "hidden",
                textOverflow: "ellipsis",
                // inline-block breaks the underline, re-applying it here
                ...(to.readminUrl && { textDecoration: "underline" }),
              }}
            >
              {to.readminName}
            </span>
          )}
          {!dense && to.readminName}
        </Component>
        <a
          href="#"
          {...(openViewDetailsDialog && {
            onClick: (event) => {
              if (event.ctrlKey || event.shiftKey || event.metaKey || event.altKey) return;
              event.preventDefault();
              openViewDetailsDialog(to);
            },
          })}
        >
          <Information fontSize="small" style={{ opacity: 0.2, verticalAlign: "top" }} />
        </a>
      </span>
    )
  );
}

export const ReadminLinkFragment = gql`
  fragment ReadminLinkFragment on ReadminObject {
    readminName
    readminUrl
    readminLogoUrl
  }
`;
