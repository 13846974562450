import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import VideosForm, { VideosFormFragment } from "./Form";

VideosEditDialog.useButtonProps = () => {
  return {
    icon: <Pencil />,
    content: "Edit Settings",
  };
};

export default function VideosEditDialog({ videoIds, onSubmit, onClose }) {
  const videosUpdate = useActionFragment("videosUpdate");
  const [data, dataMeta] = useData(
    gql`
      query VideosEditDialog($videoIds: [ID!]!) {
        videos(ids: $videoIds) {
          id
          ...VideosFormFragment
        }
      }
      ${VideosFormFragment}
    `,
    { videoIds },
  );

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Video Settings"
      onClose={onClose}
      onSubmit={async (formData) => {
        await videosUpdate({
          input: {
            videoIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <VideosForm videos={data?.videos} />
    </FormDialog>
  );
}
