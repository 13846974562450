import DOMPurify from "dompurify";
import getUiString from "helpers/getUiString";
import { Marked } from "marked";
import React, { useMemo } from "react";

const marked = new Marked({ breaks: true });

export default function Markdown({ uiStringKey, source = uiStringKey && getUiString(uiStringKey) }) {
  const html = useMemo(() => DOMPurify.sanitize(marked.parse(source || "")), [source]);

  return <span dangerouslySetInnerHTML={{ __html: html }} />;
}
