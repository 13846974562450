import React from "react";

import Markdown from "./Markdown";

export default function FormText({
  uiStringKey,
  children = uiStringKey && <Markdown uiStringKey={uiStringKey} />,
  ...others
}) {
  return (
    <div {...others} style={{ padding: "0 10px", ...others.style }}>
      {children}
    </div>
  );
}
