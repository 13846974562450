import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import FormFieldContainer from "controls/FormFieldContainer";
import FormText from "controls/FormText";
import b64ToBlob from "helpers/b64ToBlob";
import useLoadedImage from "helpers/useLoadedImage";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { progressBar } from "hooks/useProgressBar";
import NumberInput from "inputs/NumberInput";
import RectInput from "inputs/RectInput";
import TextInput from "inputs/TextInput";
import { isEqual } from "lodash-es";
import { CameraControl } from "mdi-material-ui";
import React, { useLayoutEffect, useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";
import { useAsyncMemo } from "use-async-memo";
import { useDebounce } from "use-debounce";

SurfaceFilterConfigDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    content: "Filter Config",
    icon: <CameraControl />,
  };
};

export default function SurfaceFilterConfigDialog({ surfaceId, onSubmit, onClose }) {
  const [data, dataMeta] = useData(
    gql`
      query SurfaceFilterConfigDialog($surfaceId: ID!) {
        surface(id: $surfaceId) {
          id
          camera {
            id
          }
          filterDefishAmount
          filterDefishOutwidth
          filterRotateDegrees
          filterCropArea
        }
      }
    `,
    { surfaceId },
  );
  const cameraId = data?.surface.camera.id;
  const surfaceFilterConfigUpdate = useActionFragment("surfaceFilterConfigUpdate");
  const previewFilterChain = useActionFragment("previewFilterChain", "jpgImageBase64,ffmpegFilterChain");

  const [video, videoSet] = useState(null);
  const [filterDefishAmount, filterDefishAmountSet] = useState(null);
  const [filterDefishOutwidth, filterDefishOutwidthSet] = useState(null);
  const [filterRotateDegrees, filterRotateDegreesSet] = useState(null);
  const [filterCropArea, filterCropAreaSet] = useState(null);

  const [filterDefishAmountDebounced] = useDebounce(filterDefishAmount, 1000, { leading: true });
  const [filterDefishOutwidthDebounced] = useDebounce(filterDefishOutwidth, 1000, { leading: true });
  const [filterRotateDegreesDebounced] = useDebounce(filterRotateDegrees, 1000, { leading: true });
  const [filterCropAreaDebounced] = useDebounce(filterCropArea, 1000, {
    equalityFn: isEqual,
    leading: true,
  });

  useLayoutEffect(() => {
    if (data && !dataMeta.loading) {
      filterDefishAmountSet(data.surface.filterDefishAmount);
      filterDefishOutwidthSet(data.surface.filterDefishOutwidth);
      filterRotateDegreesSet(data.surface.filterRotateDegrees);
      filterCropAreaSet(data.surface.filterCropArea);
    }
  }, [dataMeta.loading]);

  const rotatePreviewInput = {
    cameraId,
    videoId: video?.id,
    filterDefishAmount: filterDefishAmountDebounced,
    filterDefishOutwidth: filterDefishOutwidthDebounced,
    filterRotateDegrees: filterRotateDegreesDebounced,
  };

  const rotatePreviewUrl = useAsyncMemo(
    () =>
      progressBar(async () => {
        if (!rotatePreviewInput.cameraId) return null;
        const result = await previewFilterChain({
          input: rotatePreviewInput,
        });
        const jpgImageBase64 = result.previewFilterChain.jpgImageBase64;
        const blob = await b64ToBlob(jpgImageBase64);
        const url = URL.createObjectURL(blob);
        return url;
      }).catch(() => null),
    [JSON.stringify(rotatePreviewInput)],
  );

  const [rotatePreview] = useLoadedImage(rotatePreviewUrl);

  const previewInput = {
    ...rotatePreviewInput,
    filterCropArea: filterCropAreaDebounced,
  };

  const preview = useAsyncMemo(
    () =>
      progressBar(async () => {
        if (!previewInput.cameraId) return null;
        const result = await previewFilterChain({
          input: previewInput,
        });
        const jpgImageBase64 = result.previewFilterChain.jpgImageBase64;
        const ffmpegFilterChain = result.previewFilterChain.ffmpegFilterChain;
        const blob = await b64ToBlob(jpgImageBase64);
        const url = URL.createObjectURL(blob);
        return { url, ffmpegFilterChain };
      }).catch(() => null),
    [JSON.stringify(previewInput)],
  );

  return (
    <FormDialog
      header="Surface Filter Config"
      loading={dataMeta.loading}
      onClose={onClose}
      maxWidth="lg"
      onSubmit={async (formData) => {
        await surfaceFilterConfigUpdate({ input: { surfaceId, ...formData } });
        await onSubmit?.();
      }}
    >
      <FormText uiStringKey="readmin.surface.filter_config" />
      <Field
        label="Preview Using Video"
        value={video}
        onChange={videoSet}
        input={<ReadminObjectInput optionsTypename="Video" optionsFilters={{ for_camera_id: cameraId }} />}
        helperTextUiStringKey="readmin.surface.fields.preview_using_video"
      />
      <FormText>
        Original image size: {rotatePreview?.width || "?"}x{rotatePreview?.height || "?"}
      </FormText>
      <Field
        name="filterDefishAmount"
        label="Filter Defish Amount"
        value={filterDefishAmount}
        onChange={filterDefishAmountSet}
        input={<NumberInput min={0} max={3} step={0.01} initValue={0.6} />}
        helperTextUiStringKey="readmin.surface.fields.filter_defish_amount"
      />
      <Field
        name="filterDefishOutwidth"
        label="Filter Defish Outwidth"
        value={filterDefishOutwidth}
        onChange={filterDefishOutwidthSet}
        input={<NumberInput min={0} max={10000} initValue={rotatePreview?.width} />}
        helperTextUiStringKey="readmin.surface.fields.filter_defish_outwidth"
      />
      <Field
        name="filterRotateDegrees"
        label="Filter Rotate Degrees"
        value={filterRotateDegrees}
        onChange={filterRotateDegreesSet}
        input={<NumberInput min={-180} max={180} step={1} unit="degrees" />}
        helperTextUiStringKey="readmin.surface.fields.filter_rotate_degrees"
      />
      <Field
        name="filterCropArea"
        label="Filter Crop Area"
        value={filterCropArea}
        onChange={filterCropAreaSet}
        disabled={!rotatePreview}
        required
        input={
          <RectInput
            enableRawJSON
            backgroundImage={rotatePreview}
            round
            width={rotatePreview?.width}
            height={rotatePreview?.height}
            getInitValue={({ width, height }) => ({ x: 0, y: 0, width, height })}
          />
        }
        helperTextUiStringKey="readmin.surface.fields.filter_crop_area"
      />
      {preview && (
        <>
          <Field label="FFmpeg Filter Chain" disabled value={preview.ffmpegFilterChain} input={<TextInput />} />
          <FormFieldContainer label="Filter Result Preview">
            <img src={preview.url} alt="Filter Preview" style={{ maxWidth: "100%" }} />
          </FormFieldContainer>
        </>
      )}
    </FormDialog>
  );
}
