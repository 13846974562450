import { flatten } from "flat";
import fuzzy from "fuzzy";
import { defer, get } from "lodash-es";
import UI_STRINGS from "UI_STRINGS.yaml";

const UI_STRING_KEYS = Object.keys(flatten(UI_STRINGS));

export default function getUiString(key) {
  let uiString;
  uiString = get(UI_STRINGS, key);
  if (uiString === undefined) {
    const match = fuzzy.filter(key, UI_STRING_KEYS)[0];
    defer(() => {
      // eslint-disable-next-line no-console
      console.error(
        ...[`UI string not found for key: ${key}.`, match && `Did you mean ${match.string}?`].filter(Boolean),
      );
    });
    uiString = key;
  }

  return uiString;
}
