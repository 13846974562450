import DialogsToolbar from "controls/DialogsToolbar";
import useIsAdmin from "hooks/useIsAdmin";
import React from "react";

import ReadminPage from "../ReadminPage";
import ChangePasswordDialog from "./ChangePasswordDialog";
import ReadminDashboardCsvExports from "./CsvExports";
import ReadminDashboardSearch from "./DashboardSearch";
import ReadminDashboardRoles from "./Roles";

export default function ReadminDashboard() {
  const isAdmin = useIsAdmin();

  return (
    <ReadminPage
      //
      title="Glory League Admin"
      toolbar={<DialogsToolbar dialogs={[<ChangePasswordDialog />]} />}
    >
      {isAdmin && <ReadminDashboardSearch />}
      <ReadminDashboardCsvExports />
      <ReadminDashboardRoles />
    </ReadminPage>
  );
}
