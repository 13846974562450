import { gql } from "@apollo/client";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
// eslint-disable-next-line no-unused-vars
import { Delete, Eye, EyeOff, VideoSwitchOutline } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const VideosReCutDialog = makeConfirmActionDialog({
  mutationName: "videosReCut",
  paramName: "videoIds",
  action: "Re-cut",
  useButtonProps: () => {
    const isAdmin = useIsAdmin();

    return {
      icon: <VideoSwitchOutline />,
      hidden: !isAdmin,
      content: "Re-cut",
    };
  },
});

export const VideosRestoreOriginalVideoDialog = makeConfirmActionDialog({
  mutationName: "videosRestoreOriginalVideo",
  fragment: "videos { id originalVideoArchived }",
  paramName: "videoIds",
  action: "Restore Original Video",
  useButtonProps: ({ videoIds }) => {
    const [data] = useData(
      gql`
        query VideoRestoreOriginalVideoDialog($videoIds: [ID!]!) {
          videos(ids: $videoIds) {
            id
            originalVideoArchived
          }
        }
      `,
      { videoIds },
    );

    return {
      icon: <VideoSwitchOutline />,
      disabled: !data || !data.videos.every((v) => v.originalVideoArchived),
      content: "Restore Original Video",
    };
  },
});
