import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import { Plus } from "mdi-material-ui";
import React from "react";

import SurfacesForm from "./Form";

AddSurfaceDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    content: "Add Surface",
    icon: <Plus />,
  };
};

export default function AddSurfaceDialog({ onClose, onSubmit, cameraId }) {
  const addSurface = useActionFragment("addSurface", "surface { id }");

  return (
    <FormDialog
      onClose={onClose}
      header="Add Surface"
      onSubmit={async (formData) => {
        const result = await addSurface({ input: formData });
        await onSubmit?.(result.addSurface.surface);
      }}
    >
      <SurfacesForm
        surfaces={[
          {
            camera: cameraId && { id: cameraId, __typename: "Camera" },
          },
        ]}
      />
    </FormDialog>
  );
}
