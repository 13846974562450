import React from "react";
import AdAssetCourtPreviewTab from "readmin_pages/ad_assets/CourtPreviewTab";
import AdPresetCourtPreviewTab from "readmin_pages/ad_presets/CourtPreviewTab";
import CameraLiveImageTab from "readmin_pages/cameras/LiveImageTab";
import CameraRecordingSchedulesTab from "readmin_pages/cameras/RecordingSchedulesTab";
import ClubDashboardTab from "readmin_pages/clubs/DashboardTab";
import LeaderboardContentTab from "readmin_pages/leaderboard_tables/ContentTab";
import LiveStreamEncodingTimelineTab from "readmin_pages/live_streams/EncodingTimelineTab";
import RRORecordingsTab from "readmin_pages/recurring_recording_occurrences/RecordingsTab";
import SurfaceMotioncropPreviewTab from "readmin_pages/surfaces/MotioncropPreviewTab";
import VenueServerSmartStateTab from "readmin_pages/venue_servers/SmartStateTab";
import VenueServerStatusTab from "readmin_pages/venue_servers/StatusTab";

import { makeReadminObjectPage } from "./makeReadminObjectPage";
import ReadminAssociationDetailsTab from "./ReadminAssociationDetailsTab";
import ReadminAssociationTableTab from "./ReadminAssociationTableTab";

export const ClubPage = makeReadminObjectPage("Club", "clubId", {
  prependTabs: [
    //
    ["dashboard", "Dashboard", <ClubDashboardTab />],
  ],
  tabs: [["gameday_venues", "GameDay Venues", <ReadminAssociationTableTab associationName="gd_venues" />]],
});
export const EventVideoPage = makeReadminObjectPage("EventVideo", "eventVideoId", {
  tabs: [["video_objects", "Video Objects", <ReadminAssociationTableTab associationName="video_objects" />]],
});
export const VideoEncodingJobRequestPage = makeReadminObjectPage(
  "VideoEncodingJobRequest",
  "videoEncodingJobRequestId",
  {
    tabs: [
      [
        "video_encoding_jobs",
        "Video Encoding Jobs",
        <ReadminAssociationTableTab associationName="video_encoding_jobs" />,
      ],
    ],
  },
);
export const VideoCutAttemptPage = makeReadminObjectPage("VideoCutAttempt", "videoCutAttemptId");
export const VideoObjectPage = makeReadminObjectPage("VideoObject", "videoObjectId");
export const CityPage = makeReadminObjectPage("City", "cityId", {
  tabs: [
    //
    ["venues", "Venues", <ReadminAssociationTableTab associationName="venues" />],
  ],
});
export const VenuePage = makeReadminObjectPage("Venue", "venueId", {
  tabs: [
    ["cameras", "Cameras", <ReadminAssociationTableTab associationName="cameras" />],
    ["network_devices", "Network Devices", <ReadminAssociationTableTab associationName="network_devices" />],
    ["surfaces", "Surfaces", <ReadminAssociationTableTab associationName="surfaces" />],
    ["admins", "Admins", <ReadminAssociationTableTab associationName="venue_admins" />],
    [
      "venue_internet_speed_tests",
      "Internet Speed Tests",
      <ReadminAssociationTableTab associationName="venue_internet_speed_tests" />,
    ],
    [
      "venue_server_video_files",
      "Venue Server Video Files",
      <ReadminAssociationTableTab associationName="venue_server_video_files" />,
    ],
    ["recording_blocks", "Recording Blocks", <ReadminAssociationTableTab associationName="recording_blocks" />],
  ],
});
export const RecordingBlockPage = makeReadminObjectPage("RecordingBlock", "recordingBlockId", {
  tabs: [["exception_dates", "Exception Dates", <ReadminAssociationTableTab associationName="exception_dates" />]],
});
export const CameraModelPage = makeReadminObjectPage("CameraModel", "cameraModelId", {
  tabs: [
    //
    ["cameras", "Cameras", <ReadminAssociationTableTab associationName="cameras" />],
    ["videos", "Videos", <ReadminAssociationTableTab associationName="videos" />],
  ],
});
export const MotioncropTestPage = makeReadminObjectPage("MotioncropTest", "motioncropTestId");
export const MotioncropTestReportPage = makeReadminObjectPage("MotioncropTestReport", "motioncropTestReportId");
export const VenueAdminPage = makeReadminObjectPage("VenueAdmin", "venueAdminId");
export const VenueServerPage = makeReadminObjectPage("VenueServer", "venueServerId", {
  tabs: [
    ["status", "Status", <VenueServerStatusTab />],
    ["cameras", "Cameras", <ReadminAssociationTableTab associationName="cameras" />],
    ["network_devices", "Network Devices", <ReadminAssociationTableTab associationName="network_devices" />],
    ["smart_state", "SMART state", <VenueServerSmartStateTab />],
    ["commands", "Commands", <ReadminAssociationTableTab associationName="commands" />],
    ["chef_runs", "Chef Runs", <ReadminAssociationTableTab associationName="chef_runs" />],
    ["outages", "Outages", <ReadminAssociationTableTab associationName="outages" />],
    [
      "venue_internet_speed_tests",
      "Internet Speed Tests",
      <ReadminAssociationTableTab associationName="venue_internet_speed_tests" />,
    ],
    ["video_files", "Video Files", <ReadminAssociationTableTab associationName="venue_server_video_files" />],
    ["video_cut_attempts", "Video Cut Attempts", <ReadminAssociationTableTab associationName="video_cut_attempts" />],
  ],
});
export const VenueServerCommandPage = makeReadminObjectPage("VenueServerCommand", "venueServerCommandId");
export const VenueServerChefRunPage = makeReadminObjectPage("VenueServerChefRun", "venueServerChefRunId");
export const VenueServerVideoFilePage = makeReadminObjectPage("VenueServerVideoFile", "venueServerVideoFileId");
export const VideoVersionPage = makeReadminObjectPage("VideoVersion", "videoVersionId");
export const UserPage = makeReadminObjectPage("User", "userId", {
  tabs: [
    //
    ["accessible_games", "Accessible Games", <ReadminAssociationTableTab associationName="user_games" />],
    ["accessible_teams", "Accessible Teams", <ReadminAssociationTableTab associationName="user_teams" />],
    ["club_admin_roles", "Club Admin Roles", <ReadminAssociationTableTab associationName="club_admins" />],
    ["venue_admin_roles", "Venue Admin Roles", <ReadminAssociationTableTab associationName="venue_admins" />],
    ["scoreekeeper_roles", "Scorekeeper Roles", <ReadminAssociationTableTab associationName="scorekeeper_users" />],
    ["delivieries", "Deliveries", <ReadminAssociationTableTab associationName="deliveries" />],
  ],
});
export const PotwVideoPage = makeReadminObjectPage("PotwVideo", "potwVideoId", {
  tabs: [
    //
    ["video_objects", "Video Objects", <ReadminAssociationTableTab associationName="video_objects" />],
  ],
});
export const PotwEntryPage = makeReadminObjectPage("PotwEntry", "potwEntryId", {
  tabs: [
    //
    ["potw_ratings", "POTW Ratings", <ReadminAssociationTableTab associationName="potw_ratings" />],
  ],
});
export const PotwRatingPage = makeReadminObjectPage("PotwRating", "potwRatingId");
export const OnsenPopupPromoPage = makeReadminObjectPage("OnsenPopupPromo", "onsenPopupPromoId");
export const CameraPage = makeReadminObjectPage("Camera", "cameraId", {
  tabs: [
    ["live_image", "Live Image", <CameraLiveImageTab />],
    ["surfaces", "Surfaces", <ReadminAssociationTableTab associationName="surfaces" />],
    ["courts", "Courts", <ReadminAssociationTableTab associationName="courts" />],
    ["outages", "Outages", <ReadminAssociationTableTab associationName="outages" />],
    ["recording_schedules", "Recording Schedules", <CameraRecordingSchedulesTab />],
    [
      "venue_server_video_files",
      "Video Files",
      <ReadminAssociationTableTab associationName="venue_server_video_files" />,
    ],
    ["videos", "Videos", <ReadminAssociationTableTab associationName="videos" />],
  ],
});
export const NetworkDevicePage = makeReadminObjectPage("NetworkDevice", "networkDeviceId");
export const GdVenuePage = makeReadminObjectPage("GdVenue", "gdVenueId");
export const GdAssociationPage = makeReadminObjectPage("GdAssociation", "gdAssociationId", {
  tabs: [["gd_venues", "GameDay Venues", <ReadminAssociationTableTab associationName="gd_venues" />]],
});
export const PlayHqOrganisationPage = makeReadminObjectPage("PlayHqOrganisation", "playHqOrganisationId");
export const AdAssetPage = makeReadminObjectPage("AdAsset", "adAssetId", {
  tabs: [["court_preview", "Court Preview", <AdAssetCourtPreviewTab />]],
});
export const AdPresetPage = makeReadminObjectPage("AdPreset", "adPresetId", {
  tabs: [["court_preview", "Court Preview", <AdPresetCourtPreviewTab />]],
});
export const LeaguePage = makeReadminObjectPage("League", "leagueId", {
  tabs: [
    ["teams", "Teams", <ReadminAssociationTableTab associationName="league_teams" />],
    ["members", "Members", <ReadminAssociationTableTab associationName="league_members" />],
    ["games", "Games", <ReadminAssociationTableTab associationName="games" />],
    [
      "scheduled_games",
      "Scheduled Games",
      <ReadminAssociationTableTab associationName="recurring_recording_occurrences" />,
    ],
    ["leaderboards", "Leaderboards", <ReadminAssociationTableTab associationName="leaderboard_tables" />],
  ],
});
export const LeaderboardTablePage = makeReadminObjectPage("LeaderboardTable", "leaderboardTableId", {
  tabs: [
    ["content", "Content", <LeaderboardContentTab />],
    ["games", "Games", <ReadminAssociationTableTab associationName="games" />],
    [
      "team_standing_entries",
      "Team Standing Entries",
      <ReadminAssociationTableTab associationName="team_standing_entries" />,
    ],
    [
      "game_player_entries",
      "Game Player Entries",
      <ReadminAssociationTableTab associationName="game_player_entries" />,
    ],
  ],
});
export const GamePage = makeReadminObjectPage("Game", "gameId", {
  tabs: [
    ["video", "Video", <ReadminAssociationDetailsTab associationName="videos" />],
    ["roster", "Roster", <ReadminAssociationTableTab associationName="game_people" />],
    ["events", "Events", <ReadminAssociationTableTab associationName="events" />],
    ["incidents", "Incidents", <ReadminAssociationTableTab associationName="incidents" tableDefaultMode="grid" />],
  ],
});
export const VideoPage = makeReadminObjectPage("Video", "videoId", {
  tabs: [
    ["recordings", "Recordings", <ReadminAssociationTableTab associationName="recordings" />],
    ["cut", "Cut", <ReadminAssociationTableTab associationName="cut_attempts" />],
    ["cut_footage", "Cut Footage", <ReadminAssociationDetailsTab associationName="venue_server_video_file" />],
    ["upload", "Upload", <ReadminAssociationTableTab associationName="upload_jobs" />],
    ["encoding", "Encoding", <ReadminAssociationTableTab associationName="encoding_job_requests" />],
    [
      "encoded_versions",
      "Encoded Versions",
      <ReadminAssociationTableTab associationName="versions" tableDefaultMode="grid" />,
    ],
  ],
});
export const EventPage = makeReadminObjectPage("Event", "eventId", {
  tabs: [["videos", "Videos", <ReadminAssociationDetailsTab associationName="event_videos" />]],
});
export const IncidentPage = makeReadminObjectPage("Incident", "incidentId", {
  tabs: [
    //
    ["incident_notes", "Notes", <ReadminAssociationDetailsTab associationName="incident_notes" />],
    ["video_objects", "Video Objects", <ReadminAssociationTableTab associationName="video_objects" />],
  ],
});
export const PublicLeaguePagePage = makeReadminObjectPage("PublicLeaguePage", "publicLeaguePageId");
export const RecurringRecordingOccurrencePage = makeReadminObjectPage(
  "RecurringRecordingOccurrence",
  "recurringRecordingOccurrenceId",
  {
    tabs: [["recordings", "Recordings", <RRORecordingsTab />]],
  },
);

export const RecordingPage = makeReadminObjectPage("Recording", "recordingId");
export const CourtPage = makeReadminObjectPage("Court", "courtId", {
  tabs: [
    ["games", "Games", <ReadminAssociationTableTab associationName="games" />],
    [
      "recurring_recordings",
      "Scheduled Games",
      <ReadminAssociationTableTab associationName="recurring_recording_occurrences" />,
    ],
    ["recordings", "Recordings", <ReadminAssociationTableTab associationName="recordings" />],
    ["videos", "Videos", <ReadminAssociationTableTab associationName="videos" />],
    ["api_mappings", "API Mappings", <ReadminAssociationTableTab associationName="api_mappings" />],
  ],
});

export const PersonPage = makeReadminObjectPage("Person", "personId", {
  tabs: [
    ["person_supporters", "Supporters", <ReadminAssociationTableTab associationName="person_supporters" />],
    ["teams", "Teams", <ReadminAssociationTableTab associationName="teams" />],
    ["games", "Games", <ReadminAssociationTableTab associationName="games" />],
    ["leaderboards", "Leaderboards", <ReadminAssociationTableTab associationName="leaderboard_tables" />],
    ["delivieries", "Deliveries", <ReadminAssociationTableTab associationName="deliveries" />],
  ],
});
export const PotwCompetitionPage = makeReadminObjectPage("PotwCompetition", "potwCompetitionId", {
  tabs: [
    //
    ["entries", "Entries", <ReadminAssociationTableTab associationName="potw_entries" />],
    ["potw_video", "POTW Videos", <ReadminAssociationDetailsTab associationName="potw_videos" />],
  ],
});
export const TeamPage = makeReadminObjectPage("Team", "teamId", {
  tabs: [
    ["roster", "Roster", <ReadminAssociationTableTab associationName="team_members" />],
    ["registration", "Registration Form", <ReadminAssociationDetailsTab associationName="team_registration" />],
    ["games", "Games", <ReadminAssociationTableTab associationName="games" />],
    ["leaderboards", "Leaderboards", <ReadminAssociationTableTab associationName="leaderboard_tables" />],
    [
      "season_leaderboards",
      "Season Leaderboards",
      <ReadminAssociationTableTab associationName="season_leaderboard_tables" />,
    ],
  ],
});

export const TeamRegistrationPage = makeReadminObjectPage("TeamRegistration", "teamRegistrationId");

export const UsageReportEntryPage = makeReadminObjectPage("UsageReportEntry", "usageReportEntryId", {
  tabs: [["team_entries", "Teams", <ReadminAssociationTableTab associationName="team_entries" />]],
  noDetailsTab: true,
});
export const ClubAdminPage = makeReadminObjectPage("ClubAdmin", "clubAdminId");

export const ApiPartnerPage = makeReadminObjectPage("ApiPartner", "apiPartnerId", {
  tabs: [
    ["admins", "Admins", <ReadminAssociationDetailsTab associationName="api_admins" />],
    ["credentials", "Credentials", <ReadminAssociationDetailsTab associationName="api_credentials" />],
  ],
});
export const ApiPartnerApiTeamPage = makeReadminObjectPage("ApiTeam", "apiTeamId", {
  tabs: [["roster", "Roster", <ReadminAssociationTableTab associationName="api_team_members" />]],
});
export const ApiPartnerApiProfilePage = makeReadminObjectPage("ApiProfile", "apiProfileId");
export const ApiPartnerApiGradePage = makeReadminObjectPage("ApiGrade", "apiGradeId");
export const ApiPartnerApiGameSchedulePage = makeReadminObjectPage("ApiGameSchedule", "apiGameScheduleId", {
  tabs: [
    ["result", "Result", <ReadminAssociationDetailsTab associationName="api_game_results" />],
    ["roster", "Roster", <ReadminAssociationTableTab associationName="api_game_roster_entries" />],
    ["events", "Events", <ReadminAssociationTableTab associationName="api_game_events" />],
    ["gd_games", "GameDay Matches", <ReadminAssociationDetailsTab associationName="gd_games" />],
    ["gd_submissions", "GameDay Submissions", <ReadminAssociationDetailsTab associationName="gd_submissions" />],
    [
      "play_hq_game_callbacks",
      "PlayHQ Game Callbacks",
      <ReadminAssociationDetailsTab associationName="play_hq_game_callbacks" />,
    ],
  ],
});
export const ApiPartnerApiCourtPage = makeReadminObjectPage("ApiCourt", "apiCourtId", {
  tabs: [["game_schedules", "Game Schedules", <ReadminAssociationTableTab associationName="api_game_schedules" />]],
});
export const ApiPartnerApiCompetitionPage = makeReadminObjectPage("ApiCompetition", "apiCompetitionId", {
  tabs: [["game_schedules", "Game Schedules", <ReadminAssociationTableTab associationName="api_game_schedules" />]],
});
export const ApiPartnerApiOrganisationPage = makeReadminObjectPage("ApiOrganisation", "apiOrganisationId", {
  tabs: [["game_schedules", "Game Schedules", <ReadminAssociationTableTab associationName="api_game_schedules" />]],
});
export const ApiPartnerApiGraphqlRequestPage = makeReadminObjectPage("ApiGraphqlRequest", "apiGraphqlRequestId");
export const BetaFeaturePage = makeReadminObjectPage("BetaFeature", "betaFeatureId", "Beta Feature");
export const DeliveryPage = makeReadminObjectPage("Delivery", "deliveryId", {
  tabs: [
    //
    ["sendgrid_events", "SendGrid Events", <ReadminAssociationTableTab associationName="sendgrid_events" />],
  ],
});
export const GameSubmissionPage = makeReadminObjectPage("GameSubmission", "gameSubmissionId", {
  tabs: [
    //
  ],
});
export const SendgridEventPage = makeReadminObjectPage("SendgridEvent", "sendgridEventId");
export const ScorekeeperDevicePage = makeReadminObjectPage("ScorekeeperDevice", "scorekeeperDeviceId", {
  tabs: [
    //
    ["scorekeeper_games", "Scorekeeper Games", <ReadminAssociationTableTab associationName="scorekeeper_games" />],
  ],
});
export const ScorekeeperUserPage = makeReadminObjectPage("ScorekeeperUser", "scorekeeperUserId");
export const LiveStreamPage = makeReadminObjectPage("LiveStream", "liveStreamId", {
  tabs: [
    ["live_scorer", "Live Scorer", <ReadminAssociationDetailsTab associationName="live_stream_scorer" />],
    ["rtmp_outputs", "RTMP Outputs", <ReadminAssociationDetailsTab associationName="live_stream_custom_outputs" />],
    ["youtube_outputs", "YouTube Outputs", <ReadminAssociationDetailsTab associationName="live_stream_yt_outputs" />],
    ["facebook_outputs", "Facebook Outputs", <ReadminAssociationDetailsTab associationName="live_stream_fb_outputs" />],
    ["log", "Log", <ReadminAssociationTableTab associationName="live_stream_log_entries" />],
    ["encoding_timeline", "Encoding Timeline", <LiveStreamEncodingTimelineTab />],
  ],
});
export const SurfacePage = makeReadminObjectPage("Surface", "surfaceId", {
  tabs: [
    ["motioncrop_preview", "Motioncrop Preview", <SurfaceMotioncropPreviewTab />],
    ["courts", "Courts", <ReadminAssociationTableTab associationName="courts" />],
  ],
});
export const OriginalVideoRequestPage = makeReadminObjectPage("OriginalVideoRequest", "originalVideoRequestId");
export const DevicePortMappingPage = makeReadminObjectPage("DevicePortMapping", "devicePortMappingId");
