import { gql } from "@apollo/client";
import DataTable from "controls/DataTable";
import DialogsToolbar from "controls/DialogsToolbar";
import FormSection from "controls/FormSection";
import Markdown from "controls/Markdown";
import getUiString from "helpers/getUiString";
import useData from "hooks/useData";
import { uniq } from "lodash-es";
import React from "react";
import ReadminPage from "readmin_pages/ReadminPage";
import ReadminAttributeValue, { ReadminAttributeValueFragment } from "readmin_pages/shared/ReadminAttributeValue";

import CheckOpsWarningsDialog from "./CheckOpsWarningsDialog";

export default function OpsWarningsPage() {
  const [data, dataMeta] = useData(gql`
    query OpsWarningsPage {
      opsWarningCategories {
        category
        description
        opsWarnings {
          id
          detailsAttributesEntries {
            label
            ...ReadminAttributeValueFragment
          }
        }
      }
    }
    ${ReadminAttributeValueFragment}
  `);

  return (
    <ReadminPage
      title={getUiString("readmin.ops_warning.plural")}
      subheader={<Markdown uiStringKey="readmin.ops_warning.description" />}
      toolbar={
        <DialogsToolbar
          onSubmit={async () => {
            await dataMeta.refetch();
          }}
          dialogs={[<CheckOpsWarningsDialog />]}
        />
      }
    >
      {data?.opsWarningCategories.map((opsWarningCategory) => {
        const labels = uniq(
          opsWarningCategory.opsWarnings.flatMap((w) => w.detailsAttributesEntries.map((entry) => entry.label)),
        );
        let datetimeLabel = null;
        const firstColumnLabel = labels[0];
        const firstColumnEntries = opsWarningCategory.opsWarnings.map((w) =>
          w.detailsAttributesEntries.find((entry) => entry.label === firstColumnLabel),
        );
        if (firstColumnEntries.every((entry) => entry?.type === "datetime")) {
          datetimeLabel = firstColumnLabel;
        }

        return (
          <FormSection
            key={opsWarningCategory.category}
            header={`${opsWarningCategory.category} (${opsWarningCategory.opsWarnings.length})`}
            description={<Markdown source={opsWarningCategory.description} />}
          >
            {!!opsWarningCategory.opsWarnings.length && (
              <DataTable
                columns={labels}
                rows={opsWarningCategory.opsWarnings.map((w) => {
                  const entries = labels.map((l) => w.detailsAttributesEntries.find((e) => e.label === l));
                  const datetimeEntry =
                    datetimeLabel && w.detailsAttributesEntries.find((e) => e.label === datetimeLabel);

                  return {
                    key: w.id,
                    datetime: datetimeEntry?.value,
                    cells: entries.map((entry) => <ReadminAttributeValue key={entry.label} {...entry} />),
                  };
                })}
              />
            )}
          </FormSection>
        );
      })}
    </ReadminPage>
  );
}
