import { createContext } from "react";

export const readminPageContext = createContext({});
export const timeZoneContext = createContext();
export const formContext = createContext({});
export const dialogContext = createContext({
  isInDialog: false,
});

export const readminSidebarContentContext = createContext();
export const readminSidebarItemContext = createContext({
  level: -1,
  items: [],
  ancestors: [],
});
