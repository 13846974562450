import makeReadminObjectsPage from "./makeReadminObjectsPage";

export const VenuesPage = makeReadminObjectsPage("Venue");
export const NetworkDevicesPage = makeReadminObjectsPage("NetworkDevice");
export const CitiesPage = makeReadminObjectsPage("City");
export const CameraModelsPage = makeReadminObjectsPage("CameraModel");
export const RecordingBlocksPage = makeReadminObjectsPage("RecordingBlock");
export const VenueAdminsPage = makeReadminObjectsPage("VenueAdmin");
export const VideoCutAttemptsPage = makeReadminObjectsPage("VideoCutAttempt");
export const VideoObjectsPage = makeReadminObjectsPage("VideoObject");
export const ClubsPage = makeReadminObjectsPage("Club");
export const UsersPage = makeReadminObjectsPage("User");
export const MotioncropTestsPage = makeReadminObjectsPage("MotioncropTest");
export const MotioncropTestReportsPage = makeReadminObjectsPage("MotioncropTestReport");
export const OnsenPopupPromosPage = makeReadminObjectsPage("OnsenPopupPromo");
export const CamerasPage = makeReadminObjectsPage("Camera", {
  tableDefaultMode: "grid",
});
export const VenueServersPage = makeReadminObjectsPage("VenueServer");
export const VenueServerCommandsPage = makeReadminObjectsPage("VenueServerCommand");
export const VenueServerChefRunsPage = makeReadminObjectsPage("VenueServerChefRun");
export const PotwVideosPage = makeReadminObjectsPage("PotwVideo");
export const PotwEntriesPage = makeReadminObjectsPage("PotwEntry");
export const PotwRatingsPage = makeReadminObjectsPage("PotwRating");
export const EventVideosPage = makeReadminObjectsPage("EventVideo");
export const PaperTrailVersionsPage = makeReadminObjectsPage("PaperTrailVersion");
export const GdVenuesPage = makeReadminObjectsPage("GdVenue");
export const GdAssociationsPage = makeReadminObjectsPage("GdAssociation");
export const PlayHqOrganisationsPage = makeReadminObjectsPage("PlayHqOrganisation");

// ApiPartner pages
export const ApiPartnersPage = makeReadminObjectsPage("ApiPartner");
export const ApiPartnerApiOrganisationsPage = makeReadminObjectsPage("ApiOrganisation");
export const ApiPartnerApiTeamsPage = makeReadminObjectsPage("ApiTeam");
export const ApiPartnerApiProfilesPage = makeReadminObjectsPage("ApiProfile");
export const ApiPartnerApiCompetitionsPage = makeReadminObjectsPage("ApiCompetition");
export const ApiPartnerApiCourtsPage = makeReadminObjectsPage("ApiCourt");
export const ApiPartnerApiGameSchedulesPage = makeReadminObjectsPage("ApiGameSchedule");
export const ApiPartnerApiGraphqlRequestsPage = makeReadminObjectsPage("ApiGraphqlRequest");

// Club pages
export const AdAssetsPage = makeReadminObjectsPage("AdAsset");
export const AdPresetsPage = makeReadminObjectsPage("AdPreset");
export const IncidentsPage = makeReadminObjectsPage("Incident");
export const RecurringRecordingOccurrencesPage = makeReadminObjectsPage("RecurringRecordingOccurrence");
export const GamesPage = makeReadminObjectsPage("Game");
export const VideosPage = makeReadminObjectsPage("Video");
export const RecordingsPage = makeReadminObjectsPage("Recording");
export const CourtsPage = makeReadminObjectsPage("Court");
export const PeoplePage = makeReadminObjectsPage("Person");
export const PotwCompetitionsPage = makeReadminObjectsPage("PotwCompetition");
export const PublicLeaguePagesPage = makeReadminObjectsPage("PublicLeaguePage");
export const LeaguesPage = makeReadminObjectsPage("League");
export const TeamsPage = makeReadminObjectsPage("Team");
export const TeamRegistrationsPage = makeReadminObjectsPage("TeamRegistration");
export const EventsPage = makeReadminObjectsPage("Event");
export const NotesPage = makeReadminObjectsPage("Note");
export const UsageReportPage = makeReadminObjectsPage("UsageReportEntry");
export const ClubAdminsPage = makeReadminObjectsPage("ClubAdmin");
export const BetaFeaturesPage = makeReadminObjectsPage("BetaFeature");
export const DeliveriesPage = makeReadminObjectsPage("Delivery");
export const GameSubmissionsPage = makeReadminObjectsPage("GameSubmission");
export const SendgridEventsPage = makeReadminObjectsPage("SendgridEvent");
export const ScorekeeperDevicesPage = makeReadminObjectsPage("ScorekeeperDevice");
export const ScorekeeperUsersPage = makeReadminObjectsPage("ScorekeeperUser");
export const LiveStreamsPage = makeReadminObjectsPage("LiveStream");
export const SurfacesPage = makeReadminObjectsPage("Surface", {
  tableDefaultMode: "grid",
});
export const OriginalVideoRequestsPage = makeReadminObjectsPage("OriginalVideoRequest");
export const DevicePortMappingsPage = makeReadminObjectsPage("DevicePortMapping");
