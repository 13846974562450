import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { Pencil } from "mdi-material-ui";
import React from "react";

import SurfacesForm, { SurfacesFormFragment } from "./Form";

SurfacesEditDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();

  return {
    icon: <Pencil />,
    hidden: !isAdmin,
    content: "Edit Settings",
  };
};

export default function SurfacesEditDialog({ surfaceIds, onSubmit, onClose }) {
  const surfacesUpdate = useActionFragment("surfacesUpdate");
  const [data, dataMeta] = useData(
    gql`
      query SurfacesEditDialog($surfaceIds: [ID!]!) {
        surfaces(ids: $surfaceIds) {
          id
          ...SurfacesFormFragment
        }
      }
      ${SurfacesFormFragment}
    `,
    { surfaceIds },
  );

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Surface Settings"
      onClose={onClose}
      onSubmit={async (formData) => {
        await surfacesUpdate({
          input: {
            surfaceIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <SurfacesForm surfaces={data?.surfaces} />
    </FormDialog>
  );
}
