import { gql } from "@apollo/client";
import VideoThumbnail from "controls/VideoThumbnail";
import { RAILS_ENV } from "helpers/constants";
import { readminPageContext, timeZoneContext } from "helpers/contexts";
import useData from "hooks/useData";
import useFormDialogs from "hooks/useFormDialogs";
import { LOCAL_TIMEZONE } from "hooks/useTimeZone";
import { uniq } from "lodash-es";
import React, { cloneElement, useContext } from "react";
import { ReadminPageTitle } from "shared/PageTitle";

import ReadminFootor from "./shared/ReadminFooter";
import ReadminObjectViewDetailsDialog from "./shared/ViewDetailsDialog";

export default function ReadminPage({
  clubId,
  apiPartnerId,
  logoUrl,
  title,
  toolbar,
  thumbnailUrl,
  videoUrl,
  thumbnail = (thumbnailUrl || videoUrl) && <VideoThumbnail videoUrl={videoUrl} thumbnailUrl={thumbnailUrl} />,
  subheader,
  labels,
  children,
}) {
  const [data] = useData(
    gql`
      query ReadminPage($clubId: ID, $apiPartnerId: ID) {
        club(id: $clubId) {
          id
          name
          timeZone
        }
        apiPartner(id: $apiPartnerId) {
          id
          name
          timeZone
        }
      }
    `,
    { clubId, apiPartnerId },
  );

  const { timeZone, timeZoneSet } = useContext(timeZoneContext);
  const defaultTimeZone = data?.club?.timeZone || data?.apiPartner?.timeZone || LOCAL_TIMEZONE;

  const [formDialogs, formDialogsOpen] = useFormDialogs();
  const openViewDetailsDialog = (to) =>
    to && formDialogsOpen(<ReadminObjectViewDetailsDialog id={to.id} typename={to.__typename} />);

  // Don't fall back to default timezone in test environment while loading
  //
  // We always fill in club timezone value in tests. If action happens before this is loaded it might cause flaky failures.
  if (RAILS_ENV === "test" && !data) return null;

  return (
    <readminPageContext.Provider value={{ openViewDetailsDialog }}>
      <timeZoneContext.Provider value={{ timeZone, timeZoneSet, defaultTimeZone }}>
        <ReadminPageTitle
          title={uniq([
            //
            title,
            data?.club?.name,
            data?.apiPartner?.name,
          ])
            .filter((s) => s)
            .join(" | ")}
        />
        {formDialogs}
        <div
          style={{
            overflowY: "auto",
            padding: "10px 20px",
            display: "flex",
            flexFlow: "column nowrap",
            gap: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              gap: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexFlow: "column nowrap",
                gap: 10,
                flex: "1 1 auto",
              }}
            >
              <div
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: "1.5em",
                  fontWeight: "bold",
                  display: "flex",
                  flexFlow: "row wrap",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                {logoUrl && (
                  <img
                    src={logoUrl}
                    style={{
                      height: "2em",
                      width: "2em",
                      objectFit: "contain",
                    }}
                  />
                )}
                {title}
                {labels}
              </div>
              {subheader && <div>{subheader}</div>}
              {toolbar}
            </div>
            {thumbnail &&
              cloneElement(thumbnail, {
                style: {
                  height: "5em",
                  aspectRatio: "16/9",
                  float: "right",
                  ...thumbnail.props.style,
                },
              })}
          </div>
          {children}
          <ReadminFootor />
        </div>
      </timeZoneContext.Provider>
    </readminPageContext.Provider>
  );
}
