import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import sameOrNull from "helpers/sameOrNull";
import useData from "hooks/useData";
import SelectInput from "inputs/SelectInput";
import SuggestiveTextInput from "inputs/SuggestiveTextInput";
import TextInput from "inputs/TextInput";
import React, { useLayoutEffect, useState } from "react";

export const CitiesFormFragment = gql`
  fragment CitiesFormFragment on City {
    name
    state
    countryCode
    timeZone
  }
`;

export default function CitiesForm({ cities = [{}] }) {
  const [data] = useData(gql`
    query CitiesForm {
      timeZones
      glCountries {
        countryCode
        name
        states
      }
    }
  `);

  const [countryCode, countryCodeSet] = useState(null);
  useLayoutEffect(() => {
    countryCodeSet(sameOrNull(cities.map((city) => city.countryCode)));
  }, [cities]);
  const glCountry = data?.glCountries.find((c) => c.countryCode === countryCode);

  return (
    <>
      <MultiValuesField
        name="name"
        label="Name"
        required
        defaultValues={cities.map((city) => city.name)}
        input={<TextInput />}
      />
      <MultiValuesField
        name="countryCode"
        label="Country"
        required
        defaultValues={cities.map((city) => city.countryCode)}
        input={<SelectInput options={data?.glCountries.map(({ countryCode, name }) => [name, countryCode])} />}
        onChange={countryCodeSet}
      />
      <MultiValuesField
        name="state"
        label="State"
        required
        defaultValues={cities.map((city) => city.state)}
        input={<SuggestiveTextInput suggestions={glCountry?.states} />}
      />
      <MultiValuesField
        name="timeZone"
        label="Time Zone"
        required
        defaultValues={cities.map((city) => city.timeZone)}
        input={<SelectInput options={data?.timeZones} />}
      />
    </>
  );
}
