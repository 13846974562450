import { gql } from "@apollo/client";
import FormText from "controls/FormText";
import MultiValuesField from "controls/MultiValuesField";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const SurfacesFormFragment = gql`
  fragment SurfacesFormFragment on Surface {
    id
    name
    canary
    scaleInput
    encodeAudio
    encodeHighQualityOutputs
    camera {
      id
    }
  }
`;

export default function SurfacesForm({ surfaces }) {
  surfaces = surfaces.map((surface) => ({
    ...surface,
  }));

  return (
    <>
      <FormText uiStringKey="readmin.surface.description" />
      <MultiValuesField
        label="Name"
        name="name"
        required
        defaultValues={surfaces?.map((surface) => surface.name)}
        input={<TextInput />}
        helperTextUiStringKey="readmin.surface.fields.name"
      />
      <MultiValuesField
        label="Camera"
        name="camera"
        required
        defaultValues={surfaces?.map((surface) => surface.camera)}
        input={<ReadminObjectInput optionsTypename="Camera" />}
        helperTextUiStringKey="readmin.surface.fields.camera"
      />
      <MultiValuesField
        label="Canary"
        name="canary"
        defaultValues={surfaces?.map((surface) => surface.canary)}
        input={<SwitchInput />}
        helperTextUiStringKey="readmin.surface.fields.canary"
      />
      <MultiValuesField
        label="Scale Input"
        name="scaleInput"
        defaultValues={surfaces?.map((surface) => surface.scaleInput)}
        input={<SwitchInput />}
        helperTextUiStringKey="readmin.surface.fields.scale_input"
      />
      <MultiValuesField
        label="Encode Audio"
        name="encodeAudio"
        defaultValues={surfaces?.map((surface) => surface.encodeAudio)}
        input={<SwitchInput />}
        helperTextUiStringKey="readmin.surface.fields.encode_audio"
      />
      <MultiValuesField
        label="Encode High Quality Outputs"
        name="encodeHighQualityOutputs"
        defaultValues={surfaces?.map((surface) => surface.encodeHighQualityOutputs)}
        input={<SwitchInput />}
        helperTextUiStringKey="readmin.surface.fields.encode_high_quality_outputs"
      />
    </>
  );
}
