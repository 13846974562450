import { gql } from "@apollo/client";
import { ButtonBase, Paper } from "@mui/material";
import Message from "controls/Message";
import getUiString from "helpers/getUiString";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import React from "react";

export default function ReadminDashboardRoles() {
  const isAdmin = useIsAdmin();
  const [data] = useData(gql`
    query ReadminDashboardRoles {
      currentUser {
        id
        clubAdmins {
          id
          club {
            id
            ...ReadminDashboardRoles_administeredObjectsFragment
          }
        }
        venueAdmins {
          id
          venue {
            id
            ...ReadminDashboardRoles_administeredObjectsFragment
          }
        }
        apiAdmins {
          id
          apiPartner {
            id
            ...ReadminDashboardRoles_administeredObjectsFragment
          }
        }
      }
      onsenUrl
    }
    fragment ReadminDashboardRoles_administeredObjectsFragment on ReadminObject {
      id
      readminName
      readminUrl
      readminLogoUrl
      __typename
    }
  `);

  const administreredObjects = data?.currentUser && [
    ...data.currentUser.clubAdmins.map((a) => a.club),
    ...data.currentUser.venueAdmins.map((a) => a.venue),
    ...data.currentUser.apiAdmins.map((a) => a.apiPartner),
  ];

  return (
    <>
      {isAdmin === false && administreredObjects?.length === 0 && (
        <Message
          type="error"
          title="No Access"
          content={
            <>
              You do no have access to Glory League Admin, did you mean to log into our{" "}
              <a href={data?.onsenUrl}>Player Portal?</a>
            </>
          }
        />
      )}
      {isAdmin && <Message content={<>You have admin access to Glory League</>} />}
      {!!administreredObjects?.length && (
        <>
          <Message content={<>Click the organisation database you would like to access</>} />
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              gap: 10,
            }}
          >
            {administreredObjects.map((object) => (
              <Paper
                key={object.id}
                component={ButtonBase}
                href={object.readminUrl}
                style={{
                  flex: "0 0 300px",
                  display: "flex",
                  flexFlow: "row nowrap",
                  padding: 10,
                  gap: 10,
                }}
              >
                {object.readminLogoUrl && (
                  <img
                    src={object.readminLogoUrl}
                    style={{ width: 50, height: 50, aspectRatio: "1/1", objectFit: "contain" }}
                    alt="Logo"
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column nowrap",
                    gap: 10,
                  }}
                >
                  <div>{object.readminName}</div>
                  <div
                    style={{
                      fontSize: "0.8em",
                      opacity: 0.8,
                    }}
                  >
                    {getUiString(`readmin.${object.__typename.toLowerCase()}.singular`)}
                  </div>
                </div>
              </Paper>
            ))}
          </div>
        </>
      )}
    </>
  );
}
