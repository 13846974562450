import { Chip } from "@mui/material";
import useShowMessage from "hooks/useShowMessage";
import { Check, ExclamationThick } from "mdi-material-ui";
import React from "react";

import Markdown from "./Markdown";

export default function DataTableLabel({
  label,
  type,
  description,
  detailedDescription = description && <Markdown source={description} />,
  ...others
}) {
  const showMessage = useShowMessage();
  const getIcon = () => {
    switch (type) {
      case "success":
        return <Check />;
      case "warning":
        return <ExclamationThick />;
      case "error":
        return <ExclamationThick />;
      default:
        return null; //default to no icon
    }
  };
  const getColor = () => {
    const knownTypes = ["success", "warning", "info", "error"];
    if (knownTypes.includes(type)) return type;
    return "default";
  };

  return (
    <Chip
      label={label}
      variant="outlined"
      size="small"
      icon={getIcon()}
      color={getColor()}
      title={description}
      onClick={
        detailedDescription &&
        (() =>
          showMessage({
            modal: true,
            header: label,
            content: detailedDescription,
          }))
      }
      {...others}
      style={{
        backgroundColor: "#fff",
        ...others.style,
      }}
    />
  );
}
