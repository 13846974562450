import { gql } from "@apollo/client";
import { FormHelperText, Slider } from "@mui/material";
import Field from "controls/Field";
import Message from "controls/Message";
import { addSeconds, differenceInSeconds, intlFormat, parseISO } from "date-fns";
import formatShortDuration from "helpers/formatShortDuration";
import useData from "hooks/useData";
import useTimeZone from "hooks/useTimeZone";
import DurationInput from "inputs/DurationInput";
import HiddenInput from "inputs/HiddenInput";
import TextInput from "inputs/TextInput";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";
import ReadminObjectsInput from "readmin_pages/shared/ReadminObjectsInput";

export const IncidentFormFragment = gql`
  fragment IncidentFormFragment on Incident {
    id
    game {
      id
    }
    gamePeople {
      id
    }
    category
    description
    videoSeconds
    videoDuration
  }
`;

const MAX_DURATION_SECONDS = 300;
const MIN_DURATION_SECONDS = 5;

export default function IncidentForm({ incident = {} }) {
  incident = {
    game: null,
    gamePerson: null,
    videoSeconds: 0,
    videoDuration: 30,
    ...incident,
  };
  const [game, gameSet] = useState(null);

  useLayoutEffect(() => {
    gameSet(incident.game);
  }, [incident.id]);

  const [data] = useData(
    gql`
      query IncidentForm($gameId: ID!) {
        game(id: $gameId) {
          id
          playedAt
          completedAt
          video {
            id
            originalVideoUrl
            cutStartTime
            cutEndTime
          }
        }
      }
    `,
    { gameId: game?.id },
    {
      skip: !game,
    },
  );

  const [timeRange, timeRangeSet] = useState([incident.videoSeconds, incident.videoSeconds + incident.videoDuration]);
  let videoStartTime = data?.game.video?.cutStartTime || data?.game.playedAt;
  let endTime = data?.game.video?.cutEndTime || data?.game.completedAt;
  videoStartTime = videoStartTime && parseISO(videoStartTime);
  endTime = endTime && parseISO(endTime);
  const durationSeconds = videoStartTime && endTime && differenceInSeconds(endTime, videoStartTime);
  const originalVideoUrl = data?.game.video?.originalVideoUrl;
  const timeZone = useTimeZone();
  const videoRef = useRef();

  const enforceVideoTimeRange = () => {
    const video = videoRef.current;
    if (!video) return;
    if (video.currentTime < timeRange[0] || video.currentTime >= timeRange[1]) video.currentTime = timeRange[0];
  };

  const formatSeconds = (seconds) => {
    const videoTime = formatShortDuration(seconds);
    const startedAt =
      videoStartTime && intlFormat(addSeconds(videoStartTime, seconds), { timeStyle: "medium", timeZone });
    return `${videoTime} (${startedAt || "???"})`;
  };

  useEffect(() => {
    enforceVideoTimeRange();
  }, [timeRange]);
  const timeRangeSetStart = (value) =>
    timeRangeSet([
      value,
      Math.max(
        //
        value + MIN_DURATION_SECONDS,
        Math.min(
          //
          value + MAX_DURATION_SECONDS,
          timeRange[1],
        ),
      ),
    ]);
  const timeRangeSetEnd = (value) =>
    timeRangeSet([
      Math.max(
        //
        value - MAX_DURATION_SECONDS,
        Math.min(
          //
          value - MIN_DURATION_SECONDS,
          timeRange[0],
        ),
      ),
      value,
    ]);

  return (
    <>
      <Field
        name="game"
        label="Game"
        defaultValue={incident.game}
        onChange={gameSet}
        disabled={!!incident.id}
        required
        input={<ReadminObjectInput optionsTypename="Game" />}
      />
      <Field
        name="gamePeople"
        label="Game People"
        defaultValue={incident.gamePeople}
        disabled={!game}
        input={
          <ReadminObjectsInput
            optionsTypename="GamePerson"
            optionsFilters={{
              for_game_id: game?.id,
            }}
          />
        }
      />
      <Field
        required
        label="Incident Start Time"
        value={timeRange[0]}
        onChange={timeRangeSetStart}
        input={<DurationInput min={0} max={timeRange[1]} />}
      />
      <Field
        required
        label="Incident Stop Time"
        value={timeRange[1]}
        onChange={timeRangeSetEnd}
        input={<DurationInput min={timeRange[0]} max={durationSeconds} />}
      />
      <FormHelperText>
        Adjust the slider to include the incident in the game. A video clip will be stored as a record of this incident.
        Game video will also be re-processed to have the section blurred out.
      </FormHelperText>
      {data && (
        <Slider
          value={timeRange}
          onChange={(event, value, activeThumb) => {
            if (activeThumb === 0) timeRangeSetStart(value[0]);
            else timeRangeSetEnd(value[1]);
          }}
          max={durationSeconds}
          valueLabelDisplay="auto"
          valueLabelFormat={formatSeconds}
        />
      )}
      {originalVideoUrl ? (
        <video
          ref={videoRef}
          src={originalVideoUrl}
          controls
          style={{ width: "100%" }}
          autoPlay
          onTimeUpdate={() => enforceVideoTimeRange()}
        />
      ) : (
        <Message info content="Original video not available" />
      )}
      <Field label="Category" name="category" required defaultValue={incident.category} input={<TextInput />} />
      <Field
        label="Description"
        name="description"
        defaultValue={incident.description}
        input={<TextInput minRows={5} multiline />}
      />
      <Field name="videoSeconds" value={timeRange[0]} input={<HiddenInput />} />
      <Field name="videoDuration" value={timeRange[1] - timeRange[0]} input={<HiddenInput />} />
    </>
  );
}
